import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
// @ts-ignore
import * as style from './CategoryProducts.module.scss';
import {Link} from "gatsby";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart, faTimes} from "@fortawesome/pro-light-svg-icons";
import Loading from "../Loading/Loading";
import {faHeart as hoverHeart} from "@fortawesome/pro-solid-svg-icons";
import {connect, ConnectedProps} from "react-redux";
import {Client, handleApiError} from "../../services/ApiService";
import {toast} from "react-toastify";
import CategoryPreview from "../CategoryPreview/CategoryPreview";
import {setBookmarkAmount} from "../../stores/bookmark/bookmarkSlice";


const mapState = ({member, auth, bookmark}) => ({
    member,
    auth,
    bookmark
})

const mapDispatch = {
    setBookmarkAmount,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type CategoryProductsProps = PropsFromRedux & {
    category: any
}
const CategoryProducts: FC<CategoryProductsProps> = ({category, member, auth, setBookmarkAmount}) => {
    const [allBookmarks, setAllBookmarks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);


    useEffect(() => {
        if (member.memberID) {
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
            }).catch(handleApiError);
        }

        if (category) {
            Client.Raw.get('Category/' + category.ID + '/allProducts?filter[Hidden]=0&filter[Draft]=0').then((res) => {
                setProducts(res.data);
                setLoading(false);
            }).catch(handleApiError);
        }
    }, [])

    

    function addBookmark(id: number) {
        Client.Bookmark.add(auth.Key, member.Member.BookmarkCollections[0].ID, id).then((res) => {
            toast.info('Produkt zur Merkliste hinzugefügt.')
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
                setBookmarkAmount(res.data.Count);
            }).catch(handleApiError);
        }).catch(handleApiError);
    }

    function removeBookmark(id: number) {
        Client.Bookmark.remove(auth.Key, member.Member.BookmarkCollections[0].ID, id).then((res) => {
            toast.info('Produkt von der Merkliste entfernt.')
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
                setBookmarkAmount(res.data.Count)
            }).catch(handleApiError);
        }).catch(handleApiError);
    }

    return (
        <div className="container">
            <h2 className={style.heading}> Produkte der Kategorie {category.Title}</h2>
            <Row>
                {typeof products !== "undefined" ?
                    <>
                        {products.length !== 0 ?
                            <>
                                {products.map((product: any, index: number) =>
                                    <>
                                        {product.Hidden !== 1 && product.VendorID !== 0 && product.CategoryURLTitle !== undefined ?
                                            <Col className={style.holder + ' ' + product.Title} lg={4} xs={6}
                                                 key={index}>
                                                <Link to={`/${product.CategoryURLTitle}/${product.URLTitle}`}
                                                      className={style.link}>
                                                    <div className={style.iconHolder}>
                                                        <img src={product.PreviewImage} className={style.icon}/>
                                                    </div>
                                                </Link>
                                                {auth.Key ?
                                                    <>
                                                        <OverlayTrigger
                                                            key={"top-prod"}
                                                            placement={"top"}
                                                            overlay={
                                                                <Tooltip id={`tooltip-top-prod`}>
                                                                    {allBookmarks ?
                                                                        <>
                                                                            {(allBookmarks.find((bookmark) => {
                                                                                return bookmark.ID === product.ID;
                                                                            })) ?
                                                                                <span> Von der Merkliste entfernen </span> :
                                                                                <span> Zur Merkliste hinzufügen </span>} </> : null
                                                                    }

                                                                </Tooltip>
                                                            }

                                                        >
                                                            <>
                                                                {allBookmarks ?
                                                                    allBookmarks.find((bookmark) => bookmark.ID === product.ID) ?
                                                                        <span className={style.crossHolder}
                                                                              onClick={() => removeBookmark(product.ID)}>
                                                                <FontAwesomeIcon icon={faTimes}
                                                                                 className={style.cross}/>
                                                            </span>
                                                                        :
                                                                        <span className={style.heartHolder}
                                                                              onClick={() => {
                                                                                  addBookmark(product.ID);
                                                                                  if(window.dataLayer) {
                                                                                      // @ts-ignore
                                                                                      window.dataLayer.push('event', 'fs.productFavorited', {
                                                                                          'product_name': product.Title,
                                                                                          'product_category': product.CategoryTitle,
                                                                                          'vendor_name': product.VendorTitle,
                                                                                      });

                                                                                      let gme_add_to_wishlist = {
                                                                                          'event': 'add_to_wishlist',
                                                                                          'data': {
                                                                                              'currency': 'EUR',
                                                                                              'value': product.Price,
                                                                                              'items': [{
                                                                                                  'item_id': product.SKU,
                                                                                                  'item_name': product.Title,
                                                                                                  'item_category': product.CategoryTitle,
                                                                                                  'item_category2': product.CategoryTitle2,
                                                                                                  'item_category3': product.CategoryTitle3,
                                                                                                  'discount': product.Sale ? product.Price - product.RefPrice : 0,
                                                                                              }]
                                                                                          }
                                                                                      }

                                                                                      // @ts-ignore
                                                                                      window.dataLayer.push(gme_add_to_wishlist);
                                                                                  }
                                                                              }}>
                                                        <FontAwesomeIcon icon={faHeart} className={style.heart}/>
                                                        <FontAwesomeIcon icon={hoverHeart}
                                                                         className={style.hoverHeartClass}/>
                                                             </span>
                                                                    :
                                                                    <>
                                                                        {member.MemberID ?

                                                                            <span className={style.heartHolder}
                                                                                  onClick={() => {
                                                                                      addBookmark(product.ID)

                                                                                      if(window.dataLayer) {
                                                                                          // @ts-ignore
                                                                                          window.dataLayer.push('event', 'fs.productFavorited', {
                                                                                              'product_name': product.Title,
                                                                                              'product_category': product.CategoryTitle,
                                                                                              'vendor_name': product.VendorTitle,
                                                                                          });

                                                                                          let gme_add_to_wishlist = {
                                                                                              'event': 'add_to_wishlist',
                                                                                              'data': {
                                                                                                  'currency': 'EUR',
                                                                                                  'value': product.Price,
                                                                                                  'items': [{
                                                                                                      'item_id': product.SKU,
                                                                                                      'item_name': product.Title,
                                                                                                      'item_category': product.CategoryTitle,
                                                                                                      'item_category2': product.CategoryTitle2,
                                                                                                      'item_category3': product.CategoryTitle3,
                                                                                                      'discount': product.Sale ? product.Price - product.RefPrice : 0,
                                                                                                  }]
                                                                                              }
                                                                                          }

                                                                                          // @ts-ignore
                                                                                          window.dataLayer.push(gme_add_to_wishlist);
                                                                                      }
                                                                                  }}>
                                                        <FontAwesomeIcon icon={faHeart} className={style.heart}/>
                                                        <FontAwesomeIcon icon={hoverHeart}
                                                                         className={style.hoverHeartClass}/>
                                                             </span> : null
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        </OverlayTrigger>

                                                    </> : null

                                                }
                                                <h5 className={style.category}>
                                                    {product.CategoryTitle}
                                                </h5>
                                                <h3 className={style.name}>
                                                    {product.Title}
                                                </h3>
                                                <h3 className={style.price}>
                                                    {product.NicePrice}
                                                </h3>
                                                <p className={style.mwst}>
                                                    inkl. MwSt.
                                                </p>

                                            </Col>
                                            : null
                                        }
                                    </>
                                )}
                            </> : (loading ? <Loading type={"border"} message={"Produkte werden geladen"}/> :
                                <h2 className={"text-center mb-5"}>Es wurden keine Produkte gefunden.</h2>)
                        }
                    </> : (loading ? <Loading type={"border"} message={"Produkte werden geladen"}/> :
                        <h2 className={"text-center mb-5"}>Es wurden keine Produkte gefunden.</h2>)
                }
            </Row>
        </div>
    )
}

export default connector(CategoryProducts);
