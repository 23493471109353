import * as React from "react";
import {FC, useEffect, useState} from "react";
import Layout from "../../components/Navigation/layout";
import CategoryHeader from "../../components/CategoryHeader/CategoryHeader";
import FreeShippingBanner from "../../components/FreeShippingBanner/FreeShippingBanner";
import CategoryProducts from "../../components/CategoryProducts/CategoryProducts";
import {Client, handleApiError} from "../../services/ApiService";
import Categories from "../../components/Categories/Categories";
import TopSellerReworked from "../../components/TopSellerReworked/TopSellerReworked";

/**
 @TODO [22.08.22] This will be removed in a future update, categories should only link to /marktplatz (with post || get parameter to autoselect filters)
 */
const CategoryPage: FC<any> = ({pageContext: {category}}) => {
    return (
        <Layout pageTitle={category.Title} description={category.MetaDescription} image={category.MainImageLink}>
            <CategoryHeader category={category}/>
            {category.ParentID && !category.Children ? <CategoryProducts category={category}/> :
                <Categories isNotHomePage={true} catID={category.ID}/>}
        </Layout>
    )
}

export default CategoryPage;
