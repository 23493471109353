// extracted by mini-css-extract-plugin
export var category = "CategoryProducts-module--category--qml0W";
export var cross = "CategoryProducts-module--cross--JIDJo";
export var crossHolder = "CategoryProducts-module--crossHolder--7Q3V0";
export var heading = "CategoryProducts-module--heading--J4dig";
export var heart = "CategoryProducts-module--heart--oJ5YY";
export var heartHolder = "CategoryProducts-module--heartHolder--fPI+x";
export var holder = "CategoryProducts-module--holder--ee4vy";
export var hoverHeartClass = "CategoryProducts-module--hoverHeartClass--bjb8R";
export var icon = "CategoryProducts-module--icon--Gz9mh";
export var iconHolder = "CategoryProducts-module--iconHolder--FAOE8";
export var link = "CategoryProducts-module--link--Vv5nZ";
export var mwst = "CategoryProducts-module--mwst--XNSn2";
export var name = "CategoryProducts-module--name--JrU38";
export var price = "CategoryProducts-module--price--+zSvQ";