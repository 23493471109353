import * as React from "react";
import {FC, useEffect} from "react";
// @ts-ignore
import * as style from './CategoryHeader.module.scss';
import {StaticImage} from "gatsby-plugin-image";


const CategoryHeader: FC<any> = ({category}) => {

    return (
        <div className={style.container}>
            {category.HeaderImageLink ?
                <img className={style.backgroundImage} src={category.HeaderImageLink}/> :
                <StaticImage src={"../../images/Headerbild-2x.jpg"} alt={"headerImage"} placeholder={"traceSVG"}
                             className={style.backgroundImage}/>
            }
            <div className={style.content}>
                <h4 className={style.category}>Kategorie</h4>
                <h2 className={style.categoryName}>{category.Title}</h2>
            </div>
        </div>
    )
}

export default CategoryHeader;
